<template>
    <div class="clientcabin">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="pt-6">
                    <p class="headline text-center">
                        Thank you for your order!
                    </p>
                    <v-img
                        src="@/assets/img/clientcabin/thanks-order.jpg"
                        position="top center"
                        max-height="30vh"
                    />
                    <div class="pt-6 text-center text-body-1">
                        <p class="text-h6">Here's What's Next</p>
                        <p class="mb-0">
                            Fill out the form below to provide more details
                            about your business so we can begin working on your
                            campaign.
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="isReady" justify="center">
                <v-col cols="12" lg="9" xl="7" class="text-center">
                    <div
                        class="type-form"
                        data-tf-live="01J9PRCH421M47Z9WHYJC7MBD6"
                        :data-tf-hidden="tfData"
                    ></div>
                    <component
                        :is="'script'"
                        type="text/javascript"
                        src="//embed.typeform.com/next/embed.js"
                    />
                    <p class="pt-4">
                        If you have any questions, feel free to
                        <a :href="mailLink">contact us</a>
                        .
                    </p>
                    <p class="my-4">We look forward to working with you!</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

const ThanksProps = Vue.extend({
    name: 'Thanks',
    props: {
        // is injected by vue-router
        product: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class Thanks extends ThanksProps {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get mailLink() {
        return `mailto:${this.options?.reseller_email}`;
    }

    get isReady() {
        return Boolean(this.options);
    }

    get tfData() {
        return [
            //'user_id=client_id',
            //'name=client_name',
            `email=${this.clientEmail}`,
            `product_id=${this.product}`,
            `r_email=${this.options?.reseller_email}`,
            `r_id=${this.options?.reseller_id}`
        ].join(',');
    }

    get clientEmail() {
        return this.$cookies.get('paypalEmail') || '';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin::v-deep {
    color: $black;
    background-color: $white;

    .type-form {
        height: 90vh;
        display: flex;
        justify-content: center;

        [data-tf-widget] {
            height: 90vh !important;
        }
    }
}
</style>
